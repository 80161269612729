<template>
  <div class="col-12 g c">
    <div class="card card-body">
      <div class="row">
        <div class="col-12 col-lg-4">
          <div class="form-group">
            <label for="">القسم</label>
            <select class="form-control" v-model="section_id">
              <option :value="null">الكل</option>
              <option
                :value="section._id"
                v-for="section in sections"
                :key="section._id"
              >
                {{ section.title }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="form-group">
            <label for="">خط السير</label>
            <select class="form-control" v-model="group_id">
              <option :value="null">الكل</option>
              <option
                :value="section._id"
                v-for="section in groups"
                :key="section._id"
              >
                {{ section.title }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="form-group">
            <label for="">تاريخ الرحلة</label>
            <input type="date" class="form-control" v-model="date" />
          </div>
        </div>
        <div class="col-12 text-center">
          <button class="btn btn-success" @click="getLogs()">
            <i class="fa fa-search"></i>
            استعراض
          </button>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header text-center">
        <h4>
          <i class="fa fa-archive"></i>
          ارشيف الرحلات (احدث 100 رحلة)
          <img
            :src="require('@/assets/images/loading.svg')"
            v-if="loading"
            alt=""
          />
        </h4>
      </div>
      <div class="card-body">
        <div class="col-12 table-responsive">
          <table class="table table-hover table-bordered custom-table">
            <thead>
              <th>التاريخ</th>
              <th>الباص</th>
              <th>خط السير</th>
              <th>المشرف</th>
              <th>تفاصيل</th>
            </thead>
            <tbody>
              <tr v-for="trip in trips" :key="trip._id" :id="'ee' + trip._id">
                <td>
                  {{ trip.date }}
                  <span class="badge bg-success" v-if="trip.go"
                    ><i class="fa fa-arrow-up"></i> ذهاب</span
                  >
                  <span class="badge bg-primary" v-if="!trip.go"
                    ><i class="fa fa-arrow-down"></i> عودة</span
                  >
                  &nbsp;
                  <span class="badge bg-danger" v-if="!trip.status"
                    ><i class="fa fa-rss"></i> جارية الآن</span
                  >
                </td>
                <td v-html="busName(trip.bus_id)"></td>
                <td v-html="groupName(trip.group_id)"></td>
                <td v-html="adminName(trip.admin_id)"></td>
                <td>
                  <button
                    class="btn btn-primary btn-sm details"
                    v-b-modal.modal-7
                    @click="
                      viewmap = false;
                      current = trip;
                      getCurrentLogs();
                    "
                  >
                    <i class="fa fa-list"></i>
                    التفاصيل
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- basic modal -->
    <b-modal
      id="modal-7"
      size="xl"
      title="تفاصيل الرحلة"
      ok-only
      ok-title="إغلاق، شكراً لك"
    >
      <div class="row">
        <div class="col-12 c g">
          <h5>مسار الرحلة</h5>
          <div v-if="mylat && current._id && viewmap">
            <l-map
              :zoom="13"
              :center="[current.locations[0].lat, current.locations[0].long]"
              style="height: 400px; width: 100%"
            >
              <l-tile-layer :url="url" />
              <l-polyline
                :lat-lngs="latlngsfun(current)"
                color="green"
              ></l-polyline>
              <l-marker
                v-for="(location, index) in current.locations"
                :key="location.time"
                :lat-lng="[location.lat, location.long]"
              >
                <l-icon>
                  <b-img
                    v-if="index == 0"
                    :src="require('@/assets/images/start2.png')"
                    height="50"
                  />
                  <span v-html="index + 1" v-if="index != 0"></span>
                </l-icon>
              </l-marker>
              <template v-for="(student, index) in students">
                <l-marker
                  v-if="
                    student.stop_location &&
                    student.group_id == current.group_id
                  "
                  :key="index"
                  :title="student.name"
                  :lat-lng="[
                    student.stop_location.lat,
                    student.stop_location.long,
                  ]"
                >
                  <l-icon :title="student.name">
                    <b-img
                      :title="student.name"
                      :src="require('@/assets/images/student4.png')"
                      height="50"
                    />
                  </l-icon> </l-marker
              ></template>
            </l-map>
          </div>
          <div class="col-12 text-center g">
            <button
              class="btn btn-relief-success btn-lg"
              @click="viewmap = true"
            >
              <i class="fa fa-map"></i>
              فتح الخريطة
            </button>
          </div>
          <div class="col-12 text-center g">
            <button class="btn btn-success btn-sm" @click="pinPath(current, 1)">
              <i class="fa fa-arrow-up"></i>
              تثبيت هذا المسار "للذهاب" لخط السير "<span
                v-html="groupName(current.group_id)"
              ></span
              >"
            </button>
            &nbsp;
            <button class="btn btn-primary btn-sm" @click="pinPath(current, 0)">
              <i class="fa fa-arrow-down"></i>
              تثبيت هذا المسار "للعودة" لخط السير "<span
                v-html="groupName(current.group_id)"
              ></span
              >"
            </button>
          </div>
        </div>
        <div class="col-12 text-center" v-if="loading2">
          <img :src="require('@/assets/images/loading.svg')" alt="" />
        </div>
        <div class="col-12 col-lg-6 g table-responsive">
          <h5>الطلاب</h5>
          <table class="table table-hover table-bordered custom-table">
            <thead>
              <th>الطالب</th>
              <th>الحالة</th>
              <th>ارسل تنبيه</th>
              <th>ملاحظات</th>
            </thead>
            <tbody>
              <tr v-for="(log, index) in current.students" :key="index">
                <td v-html="studentName(log.id)"></td>
                <td>
                  <span class="badge bg-danger" v-if="log.status == 0">
                    لم يتم التحديد
                  </span>
                  <span class="badge bg-danger" v-if="log.status == 6">
                    غائب
                  </span>
                  <span class="badge bg-info" v-if="log.status == 1">
                    استقل الباص
                  </span>
                  <span class="badge bg-success" v-if="log.status == 2">
                    نزل من الباص
                  </span>
                  <span class="badge bg-primary" v-if="log.status == 3">
                    استأذن عن الحضور
                  </span>
                  <span class="badge bg-warning" v-if="log.status == 4">
                    سيأتي بالباص ولن يعود به
                  </span>
                  <span class="badge bg-warning" v-if="log.status == 5">
                    سأتي ويعود بدون باص
                  </span>
                </td>
                <td>
                  {{ log.notification_sent ? "نعم" : "لا" }}
                </td>
                <td>
                  {{ log.details }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-12 col-lg-6 g table-responsive">
          <h5>
            LOGS - السجل
            <button class="btn btn-sm btn-secondary" @click="printTable('t_1')">
              <i class="fa fa-print"></i>
              طباعة
            </button>
          </h5>
          <div id="t_1">
            <table class="dnone">
              <tbody>
                <tr>
                  <td>المشرف: {{ adminName(current.admin_id) }}</td>
                  <td>
                    الباص:
                    {{ busName(current.bus_id) }}
                  </td>
                </tr>
                <tr>
                  <td>التاريخ: {{ current.date }}</td>
                  <td>
                    خط السير :
                    {{ groupName(current.group_id) }}
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="table table-hover table-bordered custom-table">
              <thead>
                <th>الطالب</th>
                <th>الحالة</th>
                <th>التوقيت</th>
                <th>ملاحظات</th>
              </thead>
              <tbody>
                <tr v-for="(log, index) in current.logs" :key="index">
                  <td v-html="studentName(log.id)"></td>
                  <td>
                    <span class="badge bg-danger" v-if="log.status == 0">
                      متغيب
                    </span>
                    <span class="badge bg-info" v-if="log.status == 1">
                      استقل الباص
                    </span>
                    <span class="badge bg-success" v-if="log.status == 2">
                      نزل من الباص
                    </span>
                    <span class="badge bg-primary" v-if="log.status == 3">
                      استأذن عن الحضور
                    </span>
                    <span class="badge bg-warning" v-if="log.status == 4">
                      سيأتي بالباص ولن يعود به
                    </span>
                    <span class="badge bg-warning" v-if="log.status == 5">
                      سأتي ويعود بدون باص
                    </span>
                  </td>
                  <td>
                    {{ log.time }}
                  </td>
                  <td>
                    {{ log.details }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
delete Icon.Default.prototype._getIconUrl;
import { LMap, LTileLayer, LMarker, LIcon, LPolyline } from "vue2-leaflet";
import { latLng, icon, Icon } from "leaflet";
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import { VBModal, BImg } from "bootstrap-vue";
export default {
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  components: {
    LMap,
    LTileLayer,
    BImg,
    LMarker,
    LIcon,
    LPolyline,
    latLng,
  },
  data() {
    return {
      zoom: 13,
      zoom2: 13,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      ppoint: null,
      points: [],
      latlngs: [],
      icon: icon({
        iconUrl: require("@/assets/images/bus.png"),
        iconSize: [50, 50],
        iconAnchor: [16, 37],
      }),
      ///////
      loading2: false,
      mylat: null,
      mylong: null,
      loading: true,
      trips: [],
      user: JSON.parse(localStorage.getItem("user")),
      groups: [],
      busses: [],
      students: [],
      sections: [],
      admins: [],
      current: {},
      viewmap: false,
      date: null,
      group_id: null,
      section_id: null,
    };
  },
  created() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (r) {
        g.mylat = r.coords.latitude;
        g.mylong = r.coords.longitude;
      });
    } else {
      alert("متصفحك لا يدعم الخرائط");
    }
    checkPer(this, "bus", "view");
    var g = this;
    $.post(api + "/admin/groups/list", {
      jwt: g.user.jwt,
      nod: true,
    }).then(function (r) {
      g.groups = JSON.parse(r).response;
    });
    $.post(api + "/admin/sections/list", {
      jwt: g.user.jwt,
      nod: true,
    }).then(function (r) {
      g.sections = JSON.parse(r).response;
    });
    $.post(api + "/admin/bus/list", {
      jwt: g.user.jwt,
    }).then(function (r) {
      g.busses = JSON.parse(r).response;
    });
    g.getLogs();
    $.post(api + "/admin/students/list", {
      jwt: g.user.jwt,
      filter: "{}",
    }).then(function (r) {
      g.students = JSON.parse(r).response;
    });
    $.post(api + "/admin/admins/list", {
      jwt: g.user.jwt,
      bus: [true, false],
    }).then(function (r) {
      g.admins = JSON.parse(r).response;
    });
  },
  methods: {
    getLogs() {
      var g = this;
      $.post(api + "/admin/bus/archive", {
        jwt: g.user.jwt,
        group_id: g.group_id,
        section_id: g.section_id,
        date: g.date,
      }).then(function (r) {
        g.trips = JSON.parse(r).response;
        g.loading = false;
        if (window.location.hash) {
          var o = setInterval(() => {
            if ($("#ee" + window.location.hash.replace("#", "")).length) {
              clearInterval(o);
              $("#ee" + window.location.hash.replace("#", ""))
                .find(".details")
                .click();
            }
          }, 100);
        }
      });
    },
    pinPath(current, go) {
      var g = this;
      $.post(api + "/admin/groups/pin", {
        jwt: g.user.jwt,
        data: JSON.stringify(current.locations),
        group_id: current.group_id,
        go: go,
      }).then(function (r) {
        if (JSON.parse(r).status == 100) {
          alert("تم الثبيت بنجاح");
        }
      });
    },
    latlngsfun(trip) {
      var latlngs = [];
      trip.locations.forEach(function (a) {
        latlngs.push([a.lat, a.long]);
      });
      return JSON.parse(JSON.stringify(latlngs));
    },
    groupName(id) {
      var title = `<span class='badge bg-danger'>لا يوجد</span>`;
      this.groups.forEach(function (e) {
        if (e._id == id) {
          title = e.title;
        }
      });
      return title;
    },
    busName(id) {
      var title = `<span class='badge bg-danger'>لا يوجد</span>`;
      this.busses.forEach(function (e) {
        if (e._id == id) {
          title = e.title;
        }
      });
      return title;
    },
    adminName(id) {
      var title = `<span class='badge bg-danger'>لا يوجد</span>`;
      this.admins.forEach(function (e) {
        if (e._id == id) {
          title = e.name;
        }
      });
      return title;
    },
    studentName(id) {
      var title = `<span class='badge bg-danger'>لا يوجد</span>`;
      this.students.forEach(function (e) {
        if (e._id == id) {
          title = e.name;
        }
      });
      return title;
    },
    getCurrentLogs() {
      var g = this;
      g.loading2 = true;
      $.post(api + "/admin/bus/archive-logs", {
        jwt: g.user.jwt,
        id: g.current._id,
      }).then(function (r) {
        g.current.logs = r.logs;
        g.current.students = r.students;
        g.current.locations = r.locations;
        g.loading2 = false;
      });
    },
    printTable(id) {
      var divToPrint = document.getElementById(id);
      var newWin = window.open("");
      newWin.document.write(
        `<style>*{direction:rtl} .no{display:none;}table, td, th {
        border: 1px solid;
      }

      table {
        width: 100%;
        border-collapse: collapse;
      }</style>` + divToPrint.innerHTML
      );
      newWin.print();
      newWin.close();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.5/leaflet.css";
</style>